import React from "react"
import Layout from "@components/layout"
// import Seo from "../components/seo"

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingState: true,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loadingState: false })
    }, 4000)
  }

  render() {
    return (
      <Layout>
        {/*<Seo title="404: Not found" />*/}
        <h1 style={{ textAlign: `center` }}>
          {this.state.loadingState ? `Loading...` : `404 - Page not found`}
        </h1>
      </Layout>
    )
  }
}

export default NotFoundPage
